// This file is intended for global constants that are shared across
// the application. Use this file sparingly. For constants with a single
// usage, it can be better to include them in the module scope of the
// file they're used in.

export const highlightedAttrList = {
	FUEL_TYPE: 'normalFuelType',
	ESTIMATED_DELIVERY_DATE: 'estimatedDeliveryDate',
	LOCATION: 'accountName',
	ODOMETER: 'odometer',
	CERTIFIED: 'certified',
	CONDITION: 'type',
	TRANSFER: 'sharedVehicle',
	ONSITE: 'onSiteVehicle'
};
