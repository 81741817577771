export const MIN_COLUMNS_FOR_POSITIONING = 2;
export const DEFAULT_MAX_COLUMNS_ON_SRP = 4;

/**
 * Method to calculate positions for cta or adcards based on num columns, size of inventory, and number of special cards allowed.
 * - First card will always be the last of the first row
 * - Last card will always be first in the last row
 * @param {number} invLength Length of vehicle inventory
 * @param {number} columns Number of columns per row, also is the num VehicleCards per row
 * @returns {number[]} Array of positions that cards should be placed in with even distribution.
 */
export const getCtaAndAdCardPositions = (invLength, columns, numCards = 3) => {
	const positions = [];
	const newColumns =
		columns < MIN_COLUMNS_FOR_POSITIONING
			? MIN_COLUMNS_FOR_POSITIONING
			: columns;
	const completeRows = Math.floor((invLength + numCards) / newColumns);
	const partialRows = (invLength + numCards) % newColumns;

	const first = newColumns - 1;
	positions.push(first);

	const second = Math.round((invLength + numCards) / 2);
	positions.push(second);

	if (newColumns <= 2) {
		const last =
			partialRows > 0
				? completeRows * newColumns
				: completeRows * newColumns - 2;
		positions.push(last);
	} else {
		const last =
			partialRows > 0
				? invLength + numCards - partialRows
				: (completeRows - 1) * newColumns;
		positions.push(last);
	}
	return positions;
};

/**
 * Checks the parent container class (set via the containerMaxWidth group preference)
 * to determine the max number of columns of vehicle cards.
 * If you update the number of columns per container size, you must also update in cms-web
 * https://ghe.coxautoinc.com/DDC-WebPlatform/cms-web/blob/master/htdocs/v9/media/sass/ddc-design-system/components/_vehicle-card.scss
 * @param {boolean} isBrowser Boolean that identifies whether the code is running in the browser
 * @param {string} windowId The windowId from request data
 * @returns {number} Number of allowed columns based on parent containers of SRP
 */
export const getMaxColumnsByContainer = (isBrowser = false, windowId = '') => {
	let maxColumnsInContainer = DEFAULT_MAX_COLUMNS_ON_SRP;

	if (isBrowser) {
		// smallerContainerClasses includes all except container-max-xl (the largest) and container-max-sm (not supported by the styles)
		const smallerContainerClasses = [
			'.container-max-md',
			'.container-max-lg'
		];
		let smallerContainerSelector = smallerContainerClasses
			.map(
				(container) =>
					`${container}:has([data-widget-id="${windowId}"])`
			)
			.join(', ');
		let smallerContainers;

		try {
			smallerContainers = document.querySelectorAll(
				smallerContainerSelector
			);
		} catch (_e) {
			// If we're here, then the browser does not support the :has() pseudo-class,
			// so we query for the most common listings wrapper section names
			const listingsWrappers = [
				'[data-name="srp-wrapper-combined-inner"]',
				'[data-name="srp-wrapper-combined"]',
				'[data-name="inventory-search-results-combined-1"]',
				'[data-name="inventory-search-results-combined-inner-1"]'
			];
			smallerContainerSelector = smallerContainerClasses
				.map((container) =>
					listingsWrappers
						.map((wrapper) => `${wrapper}${container}`)
						.join(', ')
				)
				.join(', ');
			smallerContainers = document.querySelectorAll(
				smallerContainerSelector
			);
		}

		if (smallerContainers.length) {
			maxColumnsInContainer = 3;
		}
	}

	return maxColumnsInContainer;
};

/**
 * Method specifically calculates the number of columns to show on the SRP based on the user's screen width and max-width of listings container.
 * If we don't have a value for the container max-width (containerColumnsPossible), we use a default max value so that columns is determined by
 * viewportWidth
 * If you update number of columns per containerColumnsPossible, you must also update in cms-web
 * https://ghe.coxautoinc.com/DDC-WebPlatform/cms-web/blob/master/htdocs/v9/media/sass/ddc-design-system/components/_vehicle-card.scss
 * @param {number} viewportWidth Current width of the screen in px
 * @param {number} containerColumnsPossible The number of columns possible based on container size
 * @returns {number} Number of columns to show on SRP
 */
export const getNumberOfColumns = (
	viewportWidth,
	containerColumnsPossible = DEFAULT_MAX_COLUMNS_ON_SRP
) => {
	let columns;
	if (viewportWidth >= 2000 && containerColumnsPossible >= 4) columns = 4;
	else if (viewportWidth >= 1440 && containerColumnsPossible >= 3)
		columns = 3;
	else if (viewportWidth >= 768 && containerColumnsPossible >= 2) columns = 2;
	else columns = 1;

	return columns;
};
