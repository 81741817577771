/* eslint-disable react/no-unused-prop-types */
import { connect } from 'react-redux';
import React from 'react';
import logger from 'ws-scripts/modules/logger';
import { ErrorBoundary } from 'wsm-error-boundary';
import {
	addNewRelicPageAction,
	setNewRelicCustomAttribute
} from 'ddc-new-relic';
import PropTypes from 'prop-types';
import { Common } from 'ws-scripts/modules/common';
import { isBrowser } from 'ws-scripts/modules/environment';
import { insertWIAPIImagesByInventory } from '../utilities/insertWIAPIImages';
import VehicleCards from '../components/VehicleCards';
import ComparePortal from '../components/ComparePortal';
import ErrorAlert from '../components/ErrorAlert';
import MediaModalContainer from '../components/MediaModalContainer';
import LowResults from '../components/noResults/LowResults';
import NoResults from '../components/NoResults';
import InfoAlert from '../components/InfoAlert';
// import VehicleCardSkeleton from '../components/vehicleCard/skeleton/VehicleCardSkeleton';
import { waitForInventory } from '../utilities/waitForInvData';
import './style.scss';
import { selectIsGridLayout } from '../features/layoutSlice';
import { getIsSmallScreen } from '../utilities/layout';

export class WidgetContainer extends React.PureComponent {
	constructor(props) {
		super(props);

		const initialInv = [...Array(6)].map((_, i) => ({
			uuid: `${i}`,
			isPlaceholder: true
		}));

		if (isBrowser) {
			waitForInventory(this);
			const { innerWidth: viewportWidth } = window;
			this.props.setLayout({
				isSmallScreen: getIsSmallScreen(viewportWidth)
			});
		}

		this.state = {
			isLoading: true,
			isRequesting: false,
			isInitialRequest: true,
			isTransition: false,
			isPortalReady: false,
			inventory: {
				accounts: {},
				incentives: {},
				pageInfo: {
					enableMediaCarousel: false
				},
				inventory: initialInv
			},
			appliedFilters: [],
			requestFailed: false,
			// if the imageDelayLoad pref is set, set the loading delay to true
			loadingDelayed: +this.props.prefs.imageDelayLoad > 0,
			alertMessage: {}
		};
	}

	componentDidMount() {
		// If imageDelayLoad preference is set, start the delay timer
		// This causes a rerender
		if (+this.props.prefs.imageDelayLoad > 0) {
			this.delayTimer = setTimeout(() => {
				// toggle delay loading off when the timer expires
				this.setState((prevState) => ({
					...prevState,
					loadingDelayed: false
				}));
			}, +this.props.prefs.imageDelayLoad);
		}

		// This causes a rerender
		// Listen for event to update with new images
		if (isBrowser) {
			window.addEventListener(
				'wiapiAllVehiclesMediaUpdated',
				this.handleImagesInsertEventlistener.bind(this)
			);
		}

		// Send info to NR about default layout
		setNewRelicCustomAttribute('srpLayout', this.props.layout.layout);
		// Send the tabOrder pref to NR. To be used in F193627 to identify sites
		// that want to display the full price stack by default when simplified listings is rolled out.
		setNewRelicCustomAttribute('srpTabOrder', this.props.prefs.tabOrder);

		// Send showEffectiveStartDate pref to NR. To be used in US1370733 to identify sites
		// that have changed the default value to investigate the impact of removing from `ws-inv-listing`
		setNewRelicCustomAttribute(
			'srpListingShowEffectiveStartDate',
			this.props.prefs.showEffectiveStartDate
		);

		if (isBrowser && window.DDC?.dataLayer?.page?.attributes) {
			window.DDC.dataLayer.page.attributes.srpLayout =
				this.props.layout.layout;
		}

		if (isBrowser && window.DDC?.InvData?.inventory?.inventory) {
			this.initializeListings();
		}

		const pubsub = (isBrowser && window.DDC?.pubsub) || undefined;

		if (pubsub) {
			// Subscribing to fetch request events in ws-inv-data
			pubsub.subscribe('ws-inv-data/inventory-request', () => {
				if (!this.state.isLoading && !this.state.isInitialRequest) {
					this.setState({ isRequesting: true });
				}
				if (this.state.isInitialRequest) {
					this.setState({ isInitialRequest: false });
				}
			});

			// These (both of these initializeListings calls in total ) cause 1 rerender
			window.DDC.pubsub.subscribe('ws-inv-data/inventory', () => {
				this.initializeListings();
			});
			// There is a chance that the topic 'ws-inv-data/inventory' is published by inv-data
			// before the inv-listing subscribes to it. This results in the listings being stuck
			// in the skeleton loader phase. This issue does not happen when faceting.

			// We fire the initializeListings function under these circumstances:
			// When the component mounts (ie: does not trigger on facet interactions),
			// AND when the state variable 'isLoading' is still true
			// AND when the DDC. InvData.inventory object is not empty (data has already been fetched)
			if (
				isBrowser &&
				this.state.isLoading &&
				isBrowser &&
				window.DDC?.InvData?.inventory?.inventory
			) {
				this.initializeListings();
			}

			// This causes a rerender
			pubsub.subscribe('ws-inv-data/facets', () => {
				this.setState({
					appliedFilters: window.DDC.InvData.getAppliedFilters()
				});
			});
			pubsub.subscribe('ws-inv-data/request-failed', () => {
				this.setState({
					requestFailed: true,
					isLoading: false
				});
				this.removeWidgetHeight();
			});
			pubsub.subscribe('instant-ePrice-completed', () => {
				let profile = window.DDC.userProfile;
				if (
					!(
						typeof profile === 'object' &&
						Object.keys(profile).length > 0
					)
				) {
					profile = null;
				}
				this.props.setUserProfile({
					userProfile: profile
				});
			});
			// Transition display for mobile on ws-inv-filters events
			// This causes rerender
			if (this.props.deviceType === 'MOBILE') {
				pubsub.subscribe('ws-inv-filters/modal-show', () => {
					this.setState({ isTransition: true });
				});
				pubsub.subscribe('ws-inv-filters/modal-hide', () => {
					this.setState({ isTransition: false });
				});
			}

			// This causes rerender
			pubsub.subscribe('ws-inv-filters/toggle-portal-ready', (data) => {
				this.setState({ isPortalReady: data });
			});
		}
	}

	componentDidUpdate() {
		const pubsub = (isBrowser && window.DDC?.pubsub) || undefined;

		if (pubsub) {
			pubsub.publish('ws-inv-listing-update');
		}

		if (isBrowser) {
			// Set a flag so the Web Integration API knows the initial inventory view has rendered.
			window.DDC.PrivateAPI = window.DDC.PrivateAPI || {};
			window.DDC.PrivateAPI.wsInvListingRendered = true;
		}

		// Required refresh to allow `.dialog` elements to initialize jQuery
		// dialog and slide-in functionality on wsm-vehicle-cta-display buttons
		if (this.props.deviceType === 'MOBILE' && isBrowser) {
			window.DDC.mobile.slidein.init();
		} else if (typeof $ === 'function') {
			$(document).trigger('modulesRefresh', {
				modules: {
					dialog: window.DDC.modules.dialog
				}
			});
		}
	}

	componentWillUnmount() {
		// Clean up listener on unmount
		if (isBrowser) {
			window.removeEventListener(
				'wiapiAllVehiclesMediaUpdated',
				this.handleImagesInsertEventlistener.bind(this)
			);
		}
		// if imageDelayLoad preference is set, clear the delayTimer
		if (+this.props.prefs.imageDelayLoad > 0) {
			clearTimeout(this.delayTimer);
		}
	}

	// handler for inserting new images on wiapi event
	handleImagesInsertEventlistener(data) {
		if (isBrowser && window.DDC?.InvData?.srpReady) {
			// get the inventory
			const { inventory: globalInventory } =
				window.DDC.InvData.getInventory();
			if (globalInventory) {
				// build the updated inventory with inserted 3rd party content
				const updatedInventory = insertWIAPIImagesByInventory(
					globalInventory,
					data.detail.content
				);
				// if imageDelayLoad preference is set, clear the delayTimer
				if (+this.props.prefs.imageDelayLoad > 0) {
					clearTimeout(this.delayTimer);
				}

				// update state with the new inventory and toggle the loading delay off
				this.setState((prevState) => {
					return {
						inventory: {
							...prevState.inventory,
							inventory: updatedInventory
						},
						loadingDelayed: false
					};
				});
			}
		}
	}

	initializeListings() {
		const invData = window.DDC.InvData;
		// get the global inventory meta data
		const globalData = invData.inventory;
		// get the new gallery content to be inserted
		const galleryContent = window?.DDC?.PrivateAPI?.getAllGalleryContent
			? window.DDC.PrivateAPI.getAllGalleryContent('vehicle-media')
			: {};

		// build the updated inventory with inserted 3rd party content
		let updatedInventory;
		if (window.DDC.InvData.srpReady) {
			updatedInventory = insertWIAPIImagesByInventory(
				globalData.inventory,
				galleryContent
			);
		}
		// get alert message
		const alertMessage = Object.prototype.hasOwnProperty.call(
			invData || {},
			'alertMessage'
		)
			? invData?.alertMessage
			: {};

		// build initialized state
		this.setState(
			{
				isRequesting: false,
				isLoading: false,
				inventory: {
					...globalData,
					...(updatedInventory && { inventory: updatedInventory })
				},
				alertMessage
			},
			() => {
				// scroll to anchor link if it exists
				const { hash } = window.location;
				if (hash) {
					window.DDC.scrollToAnchor($(hash));
				}
			}
		);
		this.removeWidgetHeight();

		// TODO: Removed GA Tracking when CTA testing is complete
		const windowFlags = window?.DDC?.Flags;
		if (windowFlags) {
			const customLabel = windowFlags['srp-cta-label-change'];
			const controlCTAs = windowFlags['srp-control-cta-buttons'];
			const trackLDFlagsForTesting = () => {
				$(document).trigger('trackEvent', {
					widgetName: 'LaunchDarkly Event',
					eventName: `srp-cta-label-change: ${customLabel}`,
					noninteraction: true
				});
				$(document).trigger('trackEvent', {
					widgetName: 'LaunchDarkly Event',
					eventName: `srp-control-cta-buttons: ${controlCTAs}`,
					noninteraction: true
				});
			};
			if (customLabel || controlCTAs) {
				if (window.DDC?.tracking?.ready) {
					trackLDFlagsForTesting();
				} else {
					$.subscribe('ddc-event-tracking-ready', () => {
						trackLDFlagsForTesting();
					});
				}
			}
		}
	}

	removeWidgetHeight() {
		const widgetAppId = `${this.props.windowId}-app-root`;
		const widget = document.getElementById(widgetAppId);

		if (widget) {
			widget.classList.remove('min-vh-100');
		}
	}

	render() {
		const {
			inventory,
			isLoading,
			requestFailed,
			appliedFilters,
			isTransition,
			isRequesting,
			loadingDelayed,
			alertMessage
		} = this.state;

		const { pageAlias } = this.props;

		return (
			<ErrorBoundary
				errorHandler={(error, errorInfo) => {
					setNewRelicCustomAttribute(
						'SRP ERROR',
						`ws-inv-listing error boundary.\n${error}`
					);
					const newError = new Error(
						`ws-inv-listing error boundary.\n${error}`
					);
					newError.originalError = error;
					newError.originalStackTrace = errorInfo.componentStack;
					logger.error(`${newError}\n${newError.originalStackTrace}`);
				}}
			>
				<Common
					render={({ prefs, labels }) => {
						const { showVideoModalButton, lowResultsCount } = prefs;
						const { pageInfo } = inventory;
						const { enableMediaCarousel } = pageInfo;
						const isNoResults =
							inventory?.pageInfo?.totalCount === 0;

						const isAlertMessageEmpty =
							alertMessage === null ||
							alertMessage === undefined ||
							(Object.keys(alertMessage).length === 0 &&
								alertMessage.constructor === Object);

						const isMyCars =
							pageAlias === 'INVENTORY_LISTING_SHOPPING_ACTIVITY';

						if (requestFailed) {
							addNewRelicPageAction(
								'WS INV LISTING REQUEST FAILED',
								{
									requestFailed: true
								}
							);
						}
						return requestFailed ? (
							<ErrorAlert
								errorLabel={labels.get(
									'TECHNICAL_DIFFICULTIES_VERBIAGE'
								)}
							/>
						) : (
							<div
								role="region"
								aria-live="polite"
								aria-busy={isLoading}
								aria-label={labels.get('MATCHING_VEHICLES')}
								// TODO add data-async-widget="true" to widget container renderer in ws-scripts
								data-async-widget
							>
								<ComparePortal />
								{isNoResults ? (
									<>
										{!isAlertMessageEmpty && isMyCars && (
											<InfoAlert {...{ alertMessage }} />
										)}
										{isAlertMessageEmpty && isMyCars && (
											<NoResults
												{...{ appliedFilters }}
											/>
										)}
										{!isMyCars && (
											<LowResults
												isNoResults={isNoResults}
												appliedFilters={appliedFilters}
											/>
										)}
									</>
								) : (
									<>
										<VehicleCards
											{...{
												inventoryObj: inventory,
												enableMediaCarousel,
												isLoading,
												isTransition,
												isRequesting,
												loadingDelayed
											}}
										/>
										{inventory?.pageInfo?.totalCount <=
											+lowResultsCount &&
										+lowResultsCount > 0 &&
										!isMyCars ? (
											<LowResults
												isNoResults={isNoResults}
												appliedFilters={appliedFilters}
											/>
										) : null}
										{!isAlertMessageEmpty && isMyCars && (
											<InfoAlert {...{ alertMessage }} />
										)}
									</>
								)}
								{showVideoModalButton === 'true' && (
									<MediaModalContainer />
								)}
							</div>
						);
					}}
				/>
			</ErrorBoundary>
		);
	}
}

WidgetContainer.propTypes = {
	deviceType: PropTypes.string.isRequired,
	windowId: PropTypes.string.isRequired,
	widgetName: PropTypes.string.isRequired,
	pageAlias: PropTypes.string.isRequired,
	layout: PropTypes.shape({
		layout: PropTypes.string.isRequired,
		useSimplifiedListing: PropTypes.bool,
		useSimplifiedListingUrlParam: PropTypes.bool
	}).isRequired,
	setUserProfile: PropTypes.func.isRequired,
	setLayout: PropTypes.func.isRequired,
	prefs: PropTypes.shape({
		imageDelayLoad: PropTypes.string,
		tabOrder: PropTypes.string,
		showEffectiveStartDate: PropTypes.string
	}),
	preloadInv: PropTypes.bool.isRequired,
	isGridLayout: PropTypes.bool
};

WidgetContainer.defaultProps = {
	prefs: {
		imageDelayLoad: '0',
		tabOrder: 'Info,Specials,Pricing',
		showEffectiveStartDate: 'true'
	}
};

const mapStateToProps = (state) => {
	const {
		requestData: { deviceType, widgetName, windowId },
		widgetInfo: { pageAlias, preloadInv },
		layout,
		prefs
	} = state;
	const isGridLayout = selectIsGridLayout(state);
	return {
		deviceType,
		widgetName,
		windowId,
		pageAlias,
		layout,
		prefs,
		preloadInv,
		isGridLayout
	};
};

const mapDispatchToProps = (dispatch) => ({
	setUserProfile: (data) =>
		dispatch({ type: 'SET_USER_PROFILE', payload: data }),
	setLayout: (data) => dispatch({ type: 'SET_LAYOUT', payload: data })
});

const Widget = connect(mapStateToProps, mapDispatchToProps)(WidgetContainer);

export default Widget;
