import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';
import { setNewRelicCustomAttribute } from 'ddc-new-relic';
import { useFlags, useLabels, usePrefs, useRequestData } from 'wsm-common-data';
import { useWindowWidth } from 'wsm-srp-utilities';
import { ErrorBoundary } from 'wsm-error-boundary';
import { useSelector, useDispatch } from 'react-redux';
import { isBrowser } from 'ws-scripts/modules/environment';
import { setOffSiteData } from '../actions/offSiteData';
import { setLayout } from '../actions/layout';
import { selectIsGridLayout } from '../features/layoutSlice';
import VehicleCard from './vehicleCard/VehicleCard';
import VehicleCardSkeleton from './vehicleCard/skeleton/VehicleCardSkeleton';
import ErrorAlert from './ErrorAlert';
import { getShouldShowCompare } from '../utilities/compare';
import { getFlagValue } from '../utilities/flags';
import { addImageParams } from '../utilities/images';
import { getIsSmallScreen } from '../utilities/layout';
import { waitForSomething } from '../utilities/waitForSomething';
import IntersectionWrapper from './IntersectionWrapper';
import {
	mergeInventoryAndCtas,
	mergeAllWithPlaceholders,
	mergeInventoryAndAds
} from '../utilities/createCTAandWIAPICards';
import {
	getMaxColumnsByContainer,
	getNumberOfColumns,
	getCtaAndAdCardPositions
} from '../utilities/getCTAandWIAPIPositions';

const VehicleCards = ({
	enableMediaCarousel,
	inventoryObj,
	isLoading,
	isRequesting,
	isTransition,
	loadingDelayed
}) => {
	const dispatch = useDispatch();
	const labels = useLabels();
	const flags = useFlags();
	const { enableCompare, enableHyundaiAds } = usePrefs();
	const { windowId } = useRequestData();

	// TODO: Remove when we have migrated to using WIS calls
	const isWIS =
		(isBrowser &&
			window.DDC?.InvData?.srpReady &&
			window.DDC?.InvData?.getIsWIS()) ||
		false;

	// Layout
	const { isSmallScreen } = useSelector((state) => state.layout);
	const viewportWidth = useWindowWidth();
	const isSmallScreenCurrent = getIsSmallScreen(viewportWidth);
	// Would probably be better to dispatch this along with layout in Widget.jsx if possible.
	// can't use useWindowWidth in class component however
	useEffect(() => {
		if (isSmallScreen !== isSmallScreenCurrent) {
			dispatch(setLayout({ isSmallScreen: isSmallScreenCurrent }));
		}
	}, [isSmallScreenCurrent]); // eslint-disable-line react-hooks/exhaustive-deps
	const isGridLayout = useSelector((state) => selectIsGridLayout(state));

	// Intersection Observer refs options and callback for intersection wrapper
	const observerRef = useRef(null);
	const observerCallback = useCallback((entries) => {
		const entry = entries[0];
		const { isIntersecting, target } = entry;
		const { uuid, location } = target.dataset;

		if (isIntersecting) {
			// Only dispatch the event if the WIAPI is available
			// and if the intersecting entry has a uuid (skeleton cards initially don't)
			if (isBrowser) {
				if (uuid || location) {
					const isLocation = location && !uuid;
					window.dispatchEvent(
						new CustomEvent('wsInvListingShown', {
							detail: {
								id: uuid || location,
								isLocation
							}
						})
					);
				}
			}
		}
	}, []);
	const srpDelayLoadDistance = getFlagValue(
		flags,
		'srp-delay-load-distance',
		'_toggleSRPDelayLoadDistance'
	);
	setNewRelicCustomAttribute('srpDelayLoadDistance', srpDelayLoadDistance);
	const observerOptions = {
		rootMargin: `${srpDelayLoadDistance}px`
	};

	// CTA + WIAPI cards
	const ctaCardItemlist = useSelector((state) => state.ctaCardItemlist);

	// Featured Promotion
	const featuredPromotionVIN =
		isBrowser && window.DDC?.InvData?.urlParams?.featuredPromotion;
	const [featuredPromotionDom, setFeaturedPromotionDom] = useState(null);

	const srpUseImagePlaceholders = getFlagValue(
		flags,
		'srp-use-image-placeholders',
		'_toggleSRPUseImagePlaceholders'
	);
	setNewRelicCustomAttribute(
		'srpUseImagePlaceholders',
		typeof srpUseImagePlaceholders === 'boolean'
			? srpUseImagePlaceholders.toString()
			: null
	);

	const shouldShowCompare = getShouldShowCompare(enableCompare);

	const dealershipName =
		(isBrowser && window.DDC?.dataLayer?.dealership?.dealershipName) || '';

	// OFFSITE
	const [offSiteDataState, setOffSiteDataState] = useState(
		(isBrowser &&
			window?.DDC?.InvData?.getFacets &&
			window.DDC?.InvData?.getFacets().offSiteData) || {
			offSiteBannerPosition: -1,
			offSiteItemCount: -1
		}
	);
	const { offSiteData } =
		(isBrowser &&
			window.DDC?.InvData?.getFacets &&
			window.DDC?.InvData?.getFacets()) ||
		{};
	let enableOffSiteToggle = false;
	enableOffSiteToggle = offSiteData && offSiteData.offSiteItemCount > -1;
	useEffect(() => {
		if (enableOffSiteToggle) {
			setOffSiteDataState({ ...offSiteData });
			dispatch(setOffSiteData(offSiteData));
		}
	}, [offSiteData, enableOffSiteToggle, dispatch]);
	const [showOffSite, setShowOffSite] = useState(
		(isBrowser && sessionStorage.getItem('showOffSite') && true) || false
	);
	let offSiteBannerPosition;
	if (enableOffSiteToggle) {
		// eslint-disable-next-line prefer-destructuring
		offSiteBannerPosition =
			offSiteDataState.offSiteBannerPosition === -1
				? 0
				: offSiteDataState.offSiteBannerPosition;
	}

	const { incentives, accounts, inventory, pageInfo } = inventoryObj;
	const [hasCarouselImages, setHasCarouselImages] = useState(false);

	/**
	 * Circulates vehicles to find if at least one vehicle has more than one image,
	 * For use such as recognizing what styling to apply to all vehicle cards
	 * @param {Vehicle} vehicles
	 */
	function mayUseCarousel(vehicles) {
		return vehicles.some((vehicle) => vehicle.images.length > 1);
	}
	/**
	 * On prop changes, use temporary inventory check and placeholder check to render set hasCarouselImages appropriately
	 */
	useEffect(() => {
		if (inventory.length && !inventory[0].isPlaceholder) {
			setHasCarouselImages(mayUseCarousel(inventory));
		}
	}, [inventory]);

	const vehicleCardDefaultProps = {
		accounts,
		enableMediaCarousel,
		incentives,
		observerRef,
		pageInfo,
		shouldShowCompare,
		loadingDelayed,
		usePlaceholderImage: srpUseImagePlaceholders,
		hasCarouselImages
	};

	// wait for featured promotion vehicle
	// todo remove and use WIS
	useEffect(() => {
		if (!isLoading && isBrowser && !isWIS) {
			if (featuredPromotionVIN) {
				// Skeleton requires uuid as well as FooterContainer to render properly
				const uuid = window.DDC.InvData?.featuredPromotion?.uuid || '';
				setFeaturedPromotionDom(<VehicleCardSkeleton uuid={uuid} />);
			}
			waitForSomething({
				where: 'DDC.InvData.featuredPromotion',
				doBefore: () => {
					window.DDC.pubsub.subscribe(
						'ws-inv-data/vehicle-request-failed',
						() => {
							setFeaturedPromotionDom(null);
						}
					);
				},
				doAfter: () => {
					const tVehicle = window.DDC.InvData.featuredPromotion;
					if (featuredPromotionVIN) {
						setFeaturedPromotionDom(
							<IntersectionWrapper
								key={tVehicle.uuid}
								observerOptions={observerOptions}
								shouldFullyRender
								onIntersectCallback={observerCallback}
							>
								<VehicleCard
									{...vehicleCardDefaultProps}
									featuredPromotion
									vehicle={tVehicle}
									key={tVehicle.uuid}
								/>
							</IntersectionWrapper>
						);
					}
				},
				onGiveUp: () => {
					setFeaturedPromotionDom(null);
				}
			});
		}
	}, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

	// update featuredPromotionDom when layout changes
	// todo remove
	useEffect(() => {
		if (
			!isLoading &&
			featuredPromotionVIN &&
			isBrowser &&
			window.DDC?.InvData?.featuredPromotion
		) {
			const featuredPromotionVehicle =
				window.DDC.InvData.featuredPromotion;
			setFeaturedPromotionDom(
				<IntersectionWrapper
					key={featuredPromotionVehicle.uuid}
					observerOptions={observerOptions}
					shouldFullyRender
					onIntersectCallback={observerCallback}
				>
					<VehicleCard
						{...vehicleCardDefaultProps}
						vehicle={featuredPromotionVehicle}
						featuredPromotion
						key={featuredPromotionVehicle.uuid}
					/>
				</IntersectionWrapper>
			);
		}
	}, [isGridLayout]); // eslint-disable-line react-hooks/exhaustive-deps

	// update featuredPromotionDom when vin is cleared
	// todo remove
	useEffect(() => {
		if (!featuredPromotionVIN) {
			setFeaturedPromotionDom(null);
		}
	}, [featuredPromotionVIN]);

	const createVehicleCard = (vehicle, inventoryIndex) => {
		const { uuid, isPlaceholder, offSite, images = [] } = vehicle;
		// Fully render cards above the fold
		const shouldThisVehicleFullyRender =
			!isPlaceholder &&
			((!isLoading && inventoryIndex === 0 && !isGridLayout) ||
				(inventoryIndex <= 2 && isGridLayout));
		const vehicleCardProps = {
			...vehicleCardDefaultProps,
			inventoryIndex,
			vehicle
		};

		const isFirstOffsiteCard = inventoryIndex === offSiteBannerPosition;

		if (
			enableOffSiteToggle &&
			offSite &&
			!isFirstOffsiteCard &&
			!showOffSite
		) {
			return null;
		}
		const placeholderImageSrc =
			(srpUseImagePlaceholders || shouldThisVehicleFullyRender) &&
			images[0]
				? addImageParams(images[0]?.uri)
				: null;
		return (
			<IntersectionWrapper
				key={uuid}
				observerOptions={observerOptions}
				onIntersectCallback={observerCallback}
				shouldFullyRender={shouldThisVehicleFullyRender}
			>
				<VehicleCard
					{...vehicleCardProps}
					key={uuid}
					placeholderImage={placeholderImageSrc}
					setShowOffSite={
						enableOffSiteToggle &&
						isFirstOffsiteCard &&
						!showOffSite
							? setShowOffSite
							: undefined
					}
				/>
			</IntersectionWrapper>
		);
	};

	let cards;

	/**
	 * Method for building list of VehicleCards merged with Ads, Content Cards, and Banner Cards
	 * First it merges Content Cards or Ads with Vehicle Inventory
	 * Second it merges the Banners with the Card list to correctly position banners to take up full row
	 * @returns
	 */
	const buildVehicleCardList = () => {
		const options = { observerOptions, observerCallback, observerRef };

		const columns = getNumberOfColumns(
			viewportWidth,
			getMaxColumnsByContainer(isBrowser, windowId)
		);
		let numCards = enableHyundaiAds === 'true' ? 3 : ctaCardItemlist.length;

		if (inventory.length <= 6) {
			numCards = 1;
		} else if (inventory.length <= 12) {
			numCards = 2;
		}
		cards = inventory.map((vehicle, vehicleIndex) =>
			createVehicleCard(vehicle, vehicleIndex)
		);

		const cardPositions = getCtaAndAdCardPositions(
			inventory.length,
			columns,
			numCards
		);

		if (enableHyundaiAds === 'true' && isGridLayout) {
			// swap second and third card when inventory is 6 or less, so that ad cards aren't positioned next to one another
			if (numCards < 2) {
				[cardPositions[1], cardPositions[2]] = [
					cardPositions[2],
					cardPositions[1]
				];
			}
			const allowedCardPositions =
				numCards < 3 ? cardPositions.slice(0, 2) : cardPositions;
			cards = mergeInventoryAndAds(cards, allowedCardPositions, options);
		} else {
			const allowedCtas =
				numCards < 3
					? ctaCardItemlist.slice(0, numCards)
					: ctaCardItemlist;
			cards = mergeInventoryAndCtas(
				cards,
				allowedCtas,
				cardPositions,
				options
			);
		}
		cards = mergeAllWithPlaceholders(
			cards,
			columns,
			options,
			inventory.length
		);
	};

	// Build vehicle card list
	if (!cards && inventory[0].isPlaceholder) {
		cards = inventory.map((vehicle, vehicleIndex) =>
			createVehicleCard(vehicle, vehicleIndex)
		);
	} else {
		buildVehicleCardList();
	}

	// Featured Promotion feature
	if (
		featuredPromotionVIN &&
		isBrowser &&
		window.DDC?.InvData?.featuredPromotion &&
		cards
	) {
		// remove vehicle if already in list
		// todo remove
		cards = cards.filter(
			(card) => card?.key !== window.DDC.InvData.featuredPromotion.uuid
		);
	}

	return (
		<React.Fragment>
			{enableOffSiteToggle &&
				offSiteBannerPosition === 0 &&
				!showOffSite && (
					<ErrorAlert
						errorLabel={labels
							.get('CLEAR_MESSAGE_OFFSITE')
							.replace('{accountName}', dealershipName)}
					/>
				)}
			<ul
				className={setClassNames([
					isGridLayout ? 'vehicle-card-grid' : 'vehicle-card-list',
					'list-unstyled',
					'transition-property-opacity',
					'transition-duration-sm',
					'transition-timing-function-standard',
					isRequesting || isTransition ? 'opacity-25' : null
				])}
			>
				{featuredPromotionDom && (
					<ErrorBoundary newRelicPageAction="WS INV LISTING FEATURED PROMOTION ERROR BOUNDARY">
						{featuredPromotionDom}
					</ErrorBoundary>
				)}
				{cards}
			</ul>
		</React.Fragment>
	);
};

VehicleCards.propTypes = {
	enableMediaCarousel: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	isRequesting: PropTypes.bool.isRequired,
	isTransition: PropTypes.bool.isRequired,
	inventoryObj: PropTypes.shape({
		incentives: PropTypes.shape({}),
		accounts: PropTypes.shape({}),
		pageInfo: PropTypes.shape({}),
		inventory: PropTypes.arrayOf(
			PropTypes.shape({
				images: PropTypes.arrayOf({}),
				isPlaceholder: PropTypes.bool
			})
		)
	}).isRequired,
	loadingDelayed: PropTypes.bool.isRequired
};

export default VehicleCards;
