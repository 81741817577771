import React from 'react';
import CtaCard from '../components/CtaCard';
import WebIntegrationListingsPlaceholder from '../components/WebIntegrationListingsPlaceholder';
import IntersectionWrapper from '../components/IntersectionWrapper';
import GenericAdCard from '../components/GenericAdCard';
import { MIN_COLUMNS_FOR_POSITIONING } from './getCTAandWIAPIPositions';

/**
 * Merges the vehicle cards with CTAs in the provided positions
 * @param {T[]} cards Array to be modified which holds the vehicle inventory, cards, and third party ads
 * @param {T[]} ctas Array of CTAs fetched from the store
 * @param {number[]} positions Array of positions where CTAs should be placed
 * @param {Object} options Options to be provided to IntersectionWrapper
 * @param {Object} options.observerOptions
 * @param {Object} options.observerRef
 * @param {Object} options.observerCallback
 * @returns {T[]} Array of VehicleCards merged with CTA cards
 */
export const mergeInventoryAndCtas = (cards, ctas, positions, options) => {
	const { observerOptions, observerRef, observerCallback } = options;
	const newCards = [...cards];
	ctas.forEach((cta, i) => {
		const ctaKey = `cta-card-${cta.position}`;
		if (positions[i]) {
			newCards.splice(
				positions[cta.position - 1],
				0,
				<IntersectionWrapper
					key={ctaKey}
					observerOptions={observerOptions}
					shouldFullyRender
					onIntersectCallback={observerCallback}
				>
					<CtaCard key={ctaKey} observerRef={observerRef} {...cta} />
				</IntersectionWrapper>
			);
		}
	});
	return newCards;
};

/**
 * Method which merges vehicle cards with Ads injected by the WIAPI in correct positions, most specific to Hyundai
 * @param {T[]} cards Array to be modified which holds the vehicle inventory, cards, and third party ads
 * @param {number[]} positions Array of positions for Ads to be placed in the vehicle inventory card list
 * @param {Object} options Options to be provided to IntersectionWrapper
 * @param {Object} options.observerOptions
 * @param {Object} options.observerRef
 * @param {Object} options.observerCallback
 * @returns Array of VehicleCards merged with 3rd party AdCards
 */
export const mergeInventoryAndAds = (cards, positions, options) => {
	const { observerOptions, observerCallback } = options;
	const newCards = [...cards];

	positions.forEach((pos, i) => {
		const adKey = `listings-ad-${i + 1}`;
		newCards.splice(
			pos,
			0,
			<IntersectionWrapper
				key={adKey}
				observerOptions={observerOptions}
				shouldFullyRender
				onIntersectCallback={observerCallback}
			>
				<GenericAdCard
					dataLocation={adKey}
					position={i + 1}
					type="listings-ad"
				/>
			</IntersectionWrapper>
		);
	});
	return newCards;
};

/**
 * Merges VehicleCard list which already contains CTAs or Ads with Banner placeholders. Banners inserted last since they take up whole row.
 * Method currently merges 5 banners into list, one for My-Wallet, one as a placeholder at the end of page, and 3 in between
 * @param {T[]} cards VehicleCard list
 * @param {number} columns Number of columns for each row of cars
 * @param {Object} options Options to be provided to IntersectionWrapper
 * @param {Object} options.observerOptions
 * @param {Object} options.observerRef
 * @param {Object} options.observerCallback
 * @param {number} additionalListings Number of additional placeholders to insert inbetween first and last listing placeholders, defaults to 3
 * @returns
 */
export const mergeAllWithPlaceholders = (
	cards,
	columns,
	options,
	invSize,
	additionalListings = 3
) => {
	const { observerOptions, observerRef, observerCallback } = options;
	const newCards = [...cards];
	const newColumns =
		columns < MIN_COLUMNS_FOR_POSITIONING
			? MIN_COLUMNS_FOR_POSITIONING
			: columns;
	let placeholder = 1;
	let placeholderKey = `listings-placeholder-${placeholder}`;
	// place my-wallet at the end of first row
	newCards.splice(
		newColumns,
		0,
		<IntersectionWrapper
			key={placeholderKey}
			observerOptions={observerOptions}
			shouldFullyRender
			onIntersectCallback={observerCallback}
		>
			<WebIntegrationListingsPlaceholder
				location={placeholderKey}
				observerRef={observerRef}
			/>
		</IntersectionWrapper>
	);
	placeholder += 1;
	placeholderKey = `listings-placeholder-${placeholder}`;

	// we require this accommodator value to account for previous additions of listings placeholders for correct placement
	let accommodatePreviousAdditions = 1;

	// insert 3 or more additional listings-placeholders in vehicle card list
	for (let i = 0; i < additionalListings; i++) {
		const pos =
			i === 0 || invSize < 13
				? newColumns * placeholder + accommodatePreviousAdditions
				: newColumns * placeholder +
				  accommodatePreviousAdditions +
				  newColumns * (i + 1) -
				  newColumns;
		newCards.splice(
			pos,
			0,
			<IntersectionWrapper
				key={placeholderKey}
				observerOptions={observerOptions}
				shouldFullyRender
				onIntersectCallback={observerCallback}
			>
				<WebIntegrationListingsPlaceholder
					location={placeholderKey}
					observerRef={observerRef}
				/>
			</IntersectionWrapper>
		);
		placeholder += 1;
		placeholderKey = `listings-placeholder-${placeholder}`;
		accommodatePreviousAdditions += 1;
	}
	// place last 3rd party placeholder
	newCards.push(
		<IntersectionWrapper
			key={placeholderKey}
			observerOptions={observerOptions}
			shouldFullyRender
			onIntersectCallback={observerCallback}
		>
			<WebIntegrationListingsPlaceholder
				location={placeholderKey}
				observerRef={observerRef}
			/>
		</IntersectionWrapper>
	);
	return newCards;
};
