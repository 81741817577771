import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { isBrowser } from 'ws-scripts/modules/environment';
import { useRequestData } from 'wsm-common-data';
import { trackEvent } from 'ddc-track-event';
import { getVehicleObj } from '../../../../utilities/vehicleTracking';
import {
	addCompareVehicle,
	removeCompareVehicle
} from '../../../../actions/compare';
import MyCarsLabel from './MyCarsLabel';

const CompareCheckbox = ({
	uuid,
	containsVehicle,
	selected,
	setSelected,
	compareVehicles
}) => {
	const dispatch = useDispatch();
	const vehicleObj = getVehicleObj(uuid);
	const { widgetName, windowId } = useRequestData();

	useEffect(() => {
		// sets the 'DDC.inventory-compare.selected' cookie which the compare page requires
		document.cookie = `DDC.inventory-compare.selected=${compareVehicles?.toString()}`;
		// update selected state if compareVehicles are updated from ComparePortal component
		setSelected(containsVehicle);
	}, [compareVehicles, containsVehicle, setSelected]);

	const handleChange = (e) => {
		if (isBrowser && window.DDC?.pushToGtmDataLayer) {
			const eventData = {
				isSRP: true,
				checked: !selected,
				element: e.target,
				type: vehicleObj.condition,
				...vehicleObj
			};
			delete eventData.condition;
			window.DDC.pushToGtmDataLayer({
				event: 'ddc.link.vehicleCompareCheck',
				eventData
			});
		}
		if (selected) {
			setSelected(false);
			dispatch(removeCompareVehicle(uuid));
			trackEvent(widgetName, windowId, {
				element: 'Deselect comparison selection link',
				result: 'Vehicle removed from comparison'
			});
		} else {
			setSelected(true);
			dispatch(addCompareVehicle(uuid));
			trackEvent(widgetName, windowId, {
				element: 'Compare selection',
				result: 'Vehicle selected for comparison'
			});
		}
	};

	return (
		<MyCarsLabel
			uuid={uuid}
			selected={selected}
			handleChange={handleChange}
		/>
	);
};

CompareCheckbox.propTypes = {
	uuid: PropTypes.string.isRequired,
	containsVehicle: PropTypes.func.isRequired,
	selected: PropTypes.bool.isRequired,
	setSelected: PropTypes.func.isRequired,
	compareVehicles: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default CompareCheckbox;
